<template>
    <v-dialog
        v-model="display"
        persistent
        width="350"
        >
        <v-card>
            <v-card-title>{{ $t('btn_confirm') }}</v-card-title>
            <v-card-text>{{ content }}</v-card-text>
            <v-card-actions>
                <v-btn text @click="submit">{{ $t('btn_confirm') }}</v-btn>
                <v-btn text @click="close">{{ $t('cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DecisionDialog",
    data() {
        return {
            display: false,
            target: null,
            id: null,
            title: null,
            content: null
        }
    },
    methods: {
        open(params) {
            this.target = params.target;
            this.title = params.title;
            this.content = params.content;
            this.id = params.id;
            this.display = true;
        },
        close() {
            this.display = false;
            this.target = null;
            this.title = null;
            this.content = null;
            this.id = null;
        },
        submit() {
            this.$emit("submit", {target: this.target, id: this.id});
            this.close();
        }
    },
    created() {
        
    }
}
</script>