<template>
  <div>
    <v-dialog width="750" persistent scrollable v-model="display">
      <v-card flat v-if="loading">
        <v-card-title></v-card-title>
        <v-card-text>
        <div class="text-center" >
                  <v-progress-circular indeterminate> </v-progress-circular>
                </div>
        </v-card-text>
      </v-card>
      <v-card v-else flat>
        <v-card-title>{{ scheme.name }} 
          <v-spacer></v-spacer>
           <v-menu
                  offset-y>
                  <template v-slot:activator="{on}">
                      <v-btn
                          icon
                          v-on="on"
                          class="mx-1">
                          <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                  </template>
                   <v-list width="250" dense>
                      <v-list-item
                          @click="AddDescription()"
                          link>
                          <v-list-item-icon>
                              <v-icon>mdi-database-plus</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title Descriptionist-item-title>{{ $t("add description") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          v-if="descSelect.length"
                          @click="EditDescription()"
                          link>
                          <v-list-item-icon>
                              <v-icon>mdi-pen</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ $t("edit description") }}</v-list-item-title>
                      </v-list-item>
                        <v-list-item
                          v-if="descSelect.length"
                          @click="openDescriptionDailog({target: 'delete', id: descSelect[0].id, content: $t('delete_msg')})"
                          link>
                          <v-list-item-icon>
                              <v-icon>mdi-delete</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ $t("delete description") }}</v-list-item-title>
                      </v-list-item>
                      
                      <v-divider></v-divider>
                      <v-list-item
                          @click="AddBenefit()"
                          link>
                          <v-list-item-icon>
                              <v-icon>mdi-database-plus</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ $t("Add Benefit") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                          v-if="coverSelect.length"
                          @click="EditBenefit()"
                          link>
                          <v-list-item-icon>
                              <v-icon>mdi-pen</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ $t("edit Benefit") }}</v-list-item-title>
                      </v-list-item>
                       <v-divider></v-divider>
                       <v-list-item
                          @click="openAgeRang()"
                          link>
                          <v-list-item-icon>
                              <v-icon>mdi-database-plus</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ $t("Add Age Range") }}</v-list-item-title>
                      </v-list-item>
                       <v-list-item
                          v-if="ageRangSelect.length"
                          @click="EditAgeRang()"
                          link>
                          <v-list-item-icon>
                              <v-icon>mdi-pen</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ $t("Edit Age Range") }}</v-list-item-title>
                      </v-list-item>
                      
                  </v-list>
              </v-menu>
        </v-card-title>

      <v-card-text>
      <v-simple-table  dense>
                  <tbody>
                    <tr>
                      <th>{{ $t("company_name") }}</th>
                      <td>{{ scheme.companyName }}</td>
                      <th>{{ $t("scheme") }}</th>
                      <td>{{ scheme.name }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("price") }}</th>
                      <td>{{ scheme.price }}</td>
                      <th>{{ $t("description") }}</th>
                      <td>{{ scheme.description }}</td>
                    </tr>
                    <tr>
                      <th>{{ $t("network") }}</th>
                      <td> <a :href="scheme.network">{{ $t("open_url") }}</a></td>
                    </tr>
                  
                  </tbody>
                </v-simple-table>
                <v-tabs v-model="tab">
                  <v-tab>{{ $t("description") }}</v-tab>
                  <v-tab>{{ $t("Benfit") }}</v-tab>
                    <v-tab>{{ $t("Age Range") }}</v-tab>
                  <v-tabs-items v-model="tab">
                    <v-tab-item>
                      <v-data-table
                        dense
                        hide-default-footer
                        disable-pagination
                        show-select
                        single-select
                      v-model="descSelect"
                        :items="scheme.descriptions"
                        :headers="headers.description"
                      >
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                      <v-data-table
                        dense
                        hide-default-footer
                        disable-pagination
                        v-model="coverSelect"
                        show-select
                        single-select
                        :items="scheme.coverages"
                        :headers="headers.coverages"
                      >
                      </v-data-table>
                    </v-tab-item>
                    <v-tab-item>
                      <v-data-table
                        dense
                        hide-default-footer
                        disable-pagination
                        v-model="ageRangSelect"
                        show-select
                        single-select
                        :items="ageRanges"
                        :headers="headers.ageRange"
                      >
                      </v-data-table>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
      </v-card-text>
      <v-card-actions>
      <v-btn text @click="close">{{ $t("close") }}</v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
    <description-all-dailog  @close="coverageDialog.show = false"
      @save="updateScheme"
      v-if="coverageDialog.show" 
      :schemeId="coverageDialog.schemeId" 
      :id="coverageDialog.id"></description-all-dailog>
       <scheme-coverages-dialog
      @close="coverageDialogs.show = false"
      @save="updateScheme"
      v-if="coverageDialogs.show" 
      :schemeId="coverageDialogs.schemeId" 
      :id="coverageDialogs.id">
    </scheme-coverages-dialog>
   
    <description-dailog 
      @close="descriptionDailog.show = false"
      @save="updateScheme"
      v-if="descriptionDailog.show" 
      :id="descriptionDailog.id" 
      :schemeId="descriptionDailog.schemeId">
      </description-dailog>
      <age-range-dailog 
        @close="ageRang.show = false"
        @save="closeAgeRang"
        v-if="ageRang.show" 
        :companyId="ageRang.schemeId" 
        :price="scheme.price" 
        :schemeId="ageRang.schemeId" 
        :id="ageRang.id">
      </age-range-dailog>
      <decision-dialog ref="descriptionDailog" @submit="onSubmitDescriptionDailog"></decision-dialog>
  </div>
  
</template>

<script>
import { mapActions } from 'vuex'
import AgeRangeDailog from '../../components/companies/ageRangeDailog.vue';
import DecisionDialog from '../../components/gen/DecisionDialog.vue';
import DescriptionAllDailog from '../../components/Scheme/DescriptionAllDailog.vue';
import DescriptionDailog from '../../components/Scheme/DescriptionDailog.vue';
import SchemeCoveragesDialog from '../../components/Scheme/SchemeCoveragesDialog.vue';
export default {
  components: {  DescriptionDailog, AgeRangeDailog, DecisionDialog, DescriptionAllDailog, SchemeCoveragesDialog },
name: "ShowScheme",
 props: ["schemeId", "companyId"],
data() {
    return {
      display: true,
      scheme: {},
      tab: null,
      loading: false,
      ageRanges: [],
      descSelect: [],
      coverSelect: [],
      ageRangSelect: [],
      coverageDialog: {
        show: false,
        id: null,
        schemeId: null
      },
      coverageDialogs: {
        show: false,
        id: null,
        schemeId: null
      },
      descriptionDailog: {
        show: false,
        id: null,
        schemeId: null
      },
      ageRang: {
        show: false,
        schemeId: null,
        companyId: null,
        price: null,
        id: null,
      },
       headers: {
        coverages: [
          {
            text: this.$t("name"),
            width: "50%",
            sortable: false,
            value: "coverageName",
          },
          {
            text: this.$t("description"),
            sortable: false,
            value: "description",
            width: "50%",
          },
        ],

        description: [
          {
            text: this.$t("name"),
           
            sortable: false,
            value: "name",
          },
          {
            text: this.$t("description"),
            sortable: false,
            value: "description",
           
          },
          {
            text: this.$t("actions"),
            sortable: false,
            value: "actions",
            width: "20%",
          },
        ],
         ageRange: [
          {
            text: this.$t("name"),
           
            sortable: false,
            value: "schemeName",
          },
           {
            text: this.$t("from"),
           
            sortable: false,
            value: "from",
          },
           {
            text: this.$t("to"),
           
            sortable: false,
            value: "to",
          },
          {
            text: this.$t("price"),
           
            sortable: false,
            value: "price",
          }
         ]
        }
    }
},
computed: {
   
},
methods: {
    ...mapActions("Api", ["getAll", "delete"]),
    ...mapActions("snackBar", ["setSnackBars"]),
    showScheme() {
        try {
             this.loading = true;
        this.getAll(`companies/${this.companyId}/schemes/${this.schemeId}`)
          .then((res) => {
            console.log(res.data.data);
            this.scheme = res.data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        } catch (error) {
            console.log('tag', error);
        }

    },
    updateScheme() {
      this.coverageDialog.show = false;
      this.descriptionDailog.show = false;
      this.showScheme();
    },
    AddBenefit() {
      this.coverageDialog.schemeId = this.schemeId;
      // this.coverageDialog.id = this.coverSelect[0].id;
      this.coverageDialog.show = true;
    },
    EditDescription() {
      this.descriptionDailog.schemeId = this.schemeId;
      this.descriptionDailog.id = this.descSelect[0].id;
      this.descriptionDailog.show = true;
    },
    //  EditBenefit() {
    //   this.coverageDialog.schemeId = this.schemeId;
    //   this.coverageDialog.id = this.coverSelect[0].id;
    //   this.coverageDialog.show = true;
    // },
     EditBenefit() {
      this.coverageDialogs.schemeId = this.schemeId;
      this.coverageDialogs.id = this.coverSelect[0].id;
      this.coverageDialogs.show = true;
    },
    AddDescription() {
      this.descriptionDailog.schemeId = this.schemeId;
      // this.descriptionDailog.id = this.descSelect[0].id;
      this.descriptionDailog.show = true;
    },

    openDescriptionDailog(params) {
      this.$refs.descriptionDailog.open(params);
    },
    deleteDescription(value) {
      this.delete(`schemes/${this.schemeId}/description/${value}`).then(() => {
         this.setSnackBars({
            color: "success",
            message: this.$t("record_delete_success"),
          });
          this.showScheme();
      }).catch((e) => {
        console.log('tag', e);
      })
    },
    onSubmitDescriptionDailog(params) {
      this.deleteDescription(params.id);
      console.log('tag', params);
    },
    getAgeRange() {
       try {
             this.loading = true;
        this.getAll(`companies/${this.companyId}/schemes/${this.schemeId}/ageRange`)
          .then((res) => {
            console.log(res.data.data);
            this.ageRanges = res.data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        } catch (error) {
            console.log('tag', error);
        } 
    },
    closeAgeRang() {
      this.getAgeRange();
      this.ageRang.show = false;
    },
    openAgeRang() {
      this.ageRang.schemeId = this.schemeId;
      this.ageRang.companyId = this.companyId;
      this.ageRanges.price = this.scheme.price;
      this.ageRang.show = true;
    },
     EditAgeRang() {
      this.ageRang.schemeId = this.schemeId;
      this.ageRang.companyId = this.companyId;
      this.ageRang.price = this.scheme.price;
      this.ageRang.id = this.ageRangSelect[0].id;
      this.ageRang.show = true;
    },
    close() {
      this.$emit("close");
    },
    
},
created() {
    this.showScheme();
    this.getAgeRange()
}
}
</script>

<style>

</style>