<template>
  <div>
    <v-card flat>
      <v-card-title
        >{{ $t("schemes") }}
        <v-spacer></v-spacer>
        <v-tooltip v-if="!isAdmin" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            @click="openScheme"
              color="success"
              depressed
              class="ma-2 white--text"
              v-bind="attrs"
              v-on="on"
            >
              {{ $t("Add New User") }}
              <v-icon right dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("Add New User") }}</span>
        </v-tooltip>
        <v-tooltip  v-if="schemesSelect.length && !isAdm" bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="blue"
                  class="ma-2 white--text"
                  depressed
                  v-bind="attrs"
                  @click="editScheme"
                  v-on="on">
                  {{ $t('Update User') }}
                  <v-icon
                      right
                      dark>
                          mdi-pen
                  </v-icon>
              </v-btn>
          </template>
          <span>{{ $t('Update User') }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          dense
          :items="schemes"
          :headers="headers.schemes"
          show-select
          single-select
          v-model="schemesSelect"
        >
          <template v-slot:item.network="{ item }">
            <a :href="item.network">{{ $t("open_url") }}</a>
          </template>
          <template v-slot:item.actions="{ item }">
           <v-menu
                    offset-y>
                    <template v-slot:activator="{on}">
                        <v-btn
                            icon
                            v-on="on"
                            class="mx-1">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list width="250" dense>
                        <v-list-item
                            @click="showSchemeDialog({item})"
                            link>
                            <v-list-item-icon>
                                <v-icon>mdi-database-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ $t("show Scheme") }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
   <scheme-dialog   v-if="scheme.show"
      :id="scheme.id"
      :companyId="scheme.companyId"
      @close="closeScheme"></scheme-dialog>
      <show-scheme  v-if="showScheme.show"
      :schemeId="showScheme.schemeId"
      :companyId="showScheme.companyId"
      @close="showScheme.show = false"></show-scheme>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import schemeDialog from '../../components/companies/schemeDialog.vue';
import ShowScheme from './ShowScheme.vue';
import store from '@/store'
export default {
  components: { schemeDialog, ShowScheme },
  name: "Schemes",
  data() {
    return {
      loading: false,
      schemes: [],
      schemesSelect: [],
       scheme: {
        show: false,
        companyId: null,
        id: null,
      },
      showScheme: {
         show: false,
        companyId: null,
        schemeId: null,
      },
      headers: {
        schemes: [
          { text: this.$t("name"), sortable: false, value: "name" },
          { text: this.$t("price"), sortable: false, value: "price" },
          {
            text: this.$t("description"),
            sortable: false,
            value: "description",
          },
          { text: this.$t("network"), sortable: false, value: "network" },
          { text: this.$t("actions"), sortable: false, value: "actions" },
        ],
      },
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(["isAdmin"]),
    isAdm() {
      return store.getters['isAdmin'];
    }
  },
  methods: {
    ...mapActions("Api", ["getAll"]),
    
    getAllSchemes() {
      try {
        this.loading = true;
        this.getAll(`companies/schemes`)
          .then((res) => {
            console.log(res.data.data);
            this.schemes = res.data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } catch {
        console.log("res.data.data");
      }
    },
    openScheme() {
      this.scheme.show = true;
      this.scheme.companyId = this.user.companyID;
      // this.scheme.id =
    },
    editScheme() {
      this.scheme.show = true;
      this.scheme.companyId = this.schemesSelect[0].companyID;
      this.scheme.id = this.schemesSelect[0].id;
    },
    closeScheme() {
       this.scheme.show = false;
       this.getAllSchemes();
    },
    showSchemeDialog(value) {
      console.log('tag', value);
      this.showScheme.companyId = value.item.companyID;
      this.showScheme.schemeId = value.item.id;
      this.showScheme.show = true;
    }
    
  },
  created() {
    this.getAllSchemes();
  },
};
</script>

<style>
</style>