var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"850","persistent":"","scrollable":""},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[(_vm.loading)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title'),_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)])],1):_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-stepper',{attrs:{"flat":""},model:{value:(_vm.curr),callback:function ($$v) {_vm.curr=$$v},expression:"curr"}},[_c('v-stepper-header',{staticClass:"elevation-0"},[_c('v-stepper-step',{attrs:{"complete":_vm.curr > 1,"step":"1","color":_vm.stepStatus(1)}},[_vm._v(" step 1 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.curr > 2,"step":"2","color":_vm.stepStatus(2)}},[_vm._v(" step 2 ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"color":_vm.stepStatus(3),"step":"3"}},[_vm._v(" step 3 ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","dense":"","headers":_vm.headers.coverages,"items":_vm.first},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.name.length <= 50)?_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","placeholder":_vm.$t('description'),"dense":"","rules":_vm.rules.required},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}):_c('v-textarea',{attrs:{"outlined":"","hide-details":"auto","placeholder":_vm.$t('description'),"dense":"","rules":_vm.rules.required},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.curr = 2}}},[_vm._v(" Continue ")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',{staticClass:"mb-12",attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","dense":"","headers":_vm.headers.coverages,"items":_vm.second},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.name.length <= 50)?_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","placeholder":_vm.$t('description'),"dense":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}):_c('v-textarea',{attrs:{"outlined":"","hide-details":"auto","placeholder":_vm.$t('description'),"dense":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.curr = 1}}},[_vm._v("Back")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.curr = 3}}},[_vm._v("Continue")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","dense":"","headers":_vm.headers.coverages,"items":_vm.third},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.name.length <= 50)?_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","placeholder":_vm.$t('description'),"dense":""},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}):_c('v-textarea',{attrs:{"outlined":"","hide-details":"auto","placeholder":_vm.$t('description'),"dense":"","rules":_vm.rules.required},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})]}}])})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.curr = 2}}},[_vm._v(" Back ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.value,"loading":_vm.btnLoading,"text":""},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("save")))]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("close")))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }