<template>
  <div>
    <v-dialog persistent width="500" v-model="display">
      <v-card flat>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
          <v-form v-model="value" ref="form">
            <v-text-field
              v-model="form.name"
              :label="$t('name')"
              :placeholder="$t('name')"
              :rules="rules.required"
              outlined
              dense
            >
            </v-text-field>
            <v-text-field
              v-model="form.term"
              :label="$t('term')"
              :placeholder="$t('term')"
              :rules="rules.required"
              outlined
              dense
            >
            </v-text-field>
            <!-- <v-text-field 
                            v-model="form.network"
                            :label="$t('network')" 
                            :placeholder="$t('network')"
                            :rules="rules.required"
                            outlined
                            dense>
                        </v-text-field> -->
            <v-text-field
              v-model="form.price"
              :label="$t('price')"
              :placeholder="$t('price')"
              :rules="rules.required"
              suffix="$"
              outlined
              dense
            >
            </v-text-field>
            <v-text-field
              v-model="form.description"
              :label="$t('description')"
              :placeholder="$t('description')"
              :rules="rules.required"
              outlined
              dense
            >
            </v-text-field>
            <v-btn icon>
             <v-icon color="primary" @click="pickFile">mdi-file</v-icon>
            </v-btn>
            <v-flex
              xs12
              class="
                text-xs-center text-sm-center text-md-center text-lg-center
              "
            >
            <v-row  v-if="form.network" dense>
              <v-col> <v-icon color="primary">mdi-file</v-icon> {{ $t('network file') }}</v-col>
              <v-col>  <a  :href="form.network">{{
                      $t("open_url")
                    }}</a></v-col>
            </v-row>
            

              <input
                type="file"
                style="display: none"
                ref="image"
                accept="image/*"
                @change="onFilePicked"
              />
            </v-flex>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text :loading="btnLoading" :disabled="!value" @click="submit">
            {{ $t("save") }}
          </v-btn>
          <v-btn text @click="close">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SchemeDialog",
  props: ["id", "companyId"],
  data() {
    return {
      display: true,
      value: false,
      btnLoading: false,
      imageName: "",
      imageUrl: "",
      imageFile: "",
      form: {
        name: null,
        term: null,
        coverage: null,
        network: null,
        insurance_company_id: null,
        price: null,
        description: null,
      },
      rules: {
        required: [(v) => !!v || this.$t("rules_required")],
      },
    };
  },
  computed: {
    title() {
      return this.id == null ? this.$t("new scheme") : this.$t("edit scheme");
    },
  },
  methods: {
    ...mapActions("Api", ["post", "getAll", "updata"]),
    ...mapActions("snackBar", ["setSnackBars"]),
    submit() {
      this.btnLoading = true;
      if (this.id == null) this.save();
      else this.editData();
      // this.$emit('close');
    },
    save() {
      this.btnLoading = true;
      let data = new FormData();
      // print(this.form.network);
      data.append("name_en", this.form.name);
      data.append("name_ar", this.form.name);
      data.append("term", this.form.term);
      // data.append('term_ar', this.form.term);
      data.append("coverage", this.form.coverage);
      // data.append('coverage_ar', this.form.coverage);
      data.append("network", this.imageFile);
      // data.append('network_ar', this.form.network);
      data.append("insurance_company_id", this.companyId);
      data.append("price", this.form.price);
      data.append("description", this.form.description);
      // data.append('description_ar', this.form.description);

      this.post({ url: `/companies/${this.companyId}/schemes`, form: data })
        .then((res) => {
          this.btnLoading = false;
          this.setSnackBars({
            color: "success",
            message: this.$t("record_save_success"),
          });
           this.$emit('Save');
          this.$emit("close");
         
          console.log(res);
        })
        .catch((error) => {
          this.btnLoading = false;
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.setSnackBars({ color: "error", message: message });
        });
    },

    editData() {
       let data = new FormData();
      // print(this.form.network);
      data.append("name_en", this.form.name);
      data.append("name_ar", this.form.name);
      data.append("term", this.form.term);
      // data.append('term_ar', this.form.term);
     
      // data.append('coverage_ar', this.form.coverage);
      data.append("network", this.imageFile);
      // data.append('network_ar', this.form.network);
      data.append("insurance_company_id", this.companyId);
      data.append("price", this.form.price);
      data.append("description", this.form.description);
       data.append('_method', 'PUT');
      // data.append('description_ar', this.form.description);

      this.post({ url: `/companies/${this.companyId}/schemes/${this.id}`, form: data })
        .then((res) => {
          this.btnLoading = false;
          this.setSnackBars({
            color: "success",
            message: this.$t("record_save_success"),
          });
           this.$emit('Save');
          this.$emit("close");
         
          console.log(res);
        })
        .catch((error) => {
          this.btnLoading = false;
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.setSnackBars({ color: "error", message: message });
        });
     
    },
    pickFile() {
      this.$refs.image.click();
    },
    getScheme(){
      //  this.loading.data = true;
      this.getAll(`companies/${this.companyId}/schemes/${this.id}`).then((res) => {
        this.form = res.data.data;
        // this.loading.data = false;
      }).catch((e) => {
        // this.loading.data = false;
        console.log('tag', e)
      })

    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName = files[0].name;
        if (this.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.form.network = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else {
        this.imageName = "";
        this.imageFile = "";
        this.imageUrl = "";
      }
    },
    close() {
      this.$emit("close");
    },
  },
  created(){
    if(this.id != null) this.getScheme();
  }
};
</script>

<style>
</style>