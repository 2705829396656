<template>
  <v-dialog persistent width="500" v-model="display">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <div class="text-center" v-if="loading.data">
        <v-progress-circular indeterminate> </v-progress-circular>
      </div>
      <v-card-text v-else>
        <v-form v-model="value" ref="form">
         
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="form.from"
                :label="$t('from')"
                :placeholder="$t('from')"
                :rules="rules.required"
                outlined
                suffix="Years"
                type="number"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="form.to"
                type="number"
                :label="$t('to')"
                :placeholder="$t('to')"
                :rules="rules.required"
                suffix="Years"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
         
          <v-text-field
            v-model="form.price"
            type="number"
            :label="$t('price')"
            :placeholder="$t('price')"
            :rules="rules.required"
            suffix="$"
            outlined
            dense
          >
          </v-text-field>
            <v-subheader style="color: red"  v-if="priceScheme">{{
            $t("price_msg") + " " + this.priceScheme
          }}</v-subheader>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text :loading="btnLoading" :disabled="!value" @click="submit">
          {{ $t("save") }}
        </v-btn>
        <v-btn text @click="close">{{ $t("close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ageRangeDailog",
  props: ["id", "companyId", "schemeId", "price"],
  data() {
    return {
      display: true,
      btnLoading: false,
      value: false,
      loading: {
        data: false,
      },
      form: {
        company_id: null,
        scheme_id: null,
        from: null,
        to: null,
        price: null,
      },
      rules: {
        required: [(v) => !!v || this.$t("rules_required")],
      },
      // key: value
    };
  },
  computed: {
    title() {
      return this.id == null
        ? this.$t("Add Age Range")
        : this.$t("Edit Age Range");
    },
    priceScheme() {
      if (this.price != null) {
        return this.price;
      } else {
        return 0;
      }
    },
  },
  methods: {
    ...mapActions("Api", ["post", "getAll", "updata"]),
    ...mapActions("snackBar", ["setSnackBars"]),
    submit() {
      this.btnLoading = true;
      this.form.company_id = this.companyId;
      this.form.scheme_id = this.schemeId;
      if(this.form.price < this.price) {
         this.setSnackBars({
            color: "success",
            message:  this.$t("price_msg") + " " + this.priceScheme,
          });
      } else {
        if(this.id == null) {
          this.post({
            url: `/companies/${this.companyId}/schemes/${this.schemeId}/ageRange`,
            form: this.form,
          })
            .then((res) => {
              this.btnLoading = false;
              this.setSnackBars({
                color: "success",
                message: this.$t("record_save_success"),
              });
              this.$emit("save");
              console.log(res);
            })
            .catch((error) => {
              this.btnLoading = false;
              let message = error.response
                ? error.response.data.message
                : error.message;
              this.setSnackBars({ color: "error", message: message });
            });
        } else {
          
               this.updata(`/companies/${this.companyId}/schemes/${this.schemeId}/ageRange/${this.id}?from=${this.form.from}&&to=${this.form.to}&&price=${this.form.price}`)
            .then((res) => {
              this.btnLoading = false;
              this.setSnackBars({
                color: "success",
                message: this.$t("record_save_success"),
              });
              this.$emit("save");
              console.log(res);
            })
            .catch((error) => {
              this.btnLoading = false;
              let message = error.response
                ? error.response.data.message
                : error.message;
              this.setSnackBars({ color: "error", message: message });
            });
        }
      }
    },
    getAgeRang() {
      this.loading.data = true;
      this.getAll(`companies/${this.companyId}/schemes/${this.schemeId}/ageRange/${this.id}`).then((res) => {
        this.form = res.data.data;
        this.loading.data = false;
      }).catch((e) => {
        this.loading.data = false;
        console.log('tag', e)
      })
    },
    close() {
      this.$emit("close");
    },
  },
  created() {
    if(this.id != null) this.getAgeRang();
  }
};
</script>

<style>
</style>