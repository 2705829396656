var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("schemes"))+" "),_c('v-spacer'),(!_vm.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"success","depressed":""},on:{"click":_vm.openScheme}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("Add New User"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")])],1)]}}],null,false,491484996)},[_c('span',[_vm._v(_vm._s(_vm.$t("Add New User")))])]):_vm._e(),(_vm.schemesSelect.length && !_vm.isAdm)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"blue","depressed":""},on:{"click":_vm.editScheme}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('Update User'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-pen ")])],1)]}}],null,false,2096747772)},[_c('span',[_vm._v(_vm._s(_vm.$t('Update User')))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"dense":"","items":_vm.schemes,"headers":_vm.headers.schemes,"show-select":"","single-select":""},scopedSlots:_vm._u([{key:"item.network",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.network}},[_vm._v(_vm._s(_vm.$t("open_url")))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"width":"250","dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.showSchemeDialog({item: item})}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-database-plus")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("show Scheme")))])],1)],1)],1)]}}]),model:{value:(_vm.schemesSelect),callback:function ($$v) {_vm.schemesSelect=$$v},expression:"schemesSelect"}})],1)],1),(_vm.scheme.show)?_c('scheme-dialog',{attrs:{"id":_vm.scheme.id,"companyId":_vm.scheme.companyId},on:{"close":_vm.closeScheme}}):_vm._e(),(_vm.showScheme.show)?_c('show-scheme',{attrs:{"schemeId":_vm.showScheme.schemeId,"companyId":_vm.showScheme.companyId},on:{"close":function($event){_vm.showScheme.show = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }