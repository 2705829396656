<template>
    <div>
        <v-dialog width="850" persistent scrollable v-model="display">
        <v-card flat v-if="loading">
        <v-card-title></v-card-title>
        <v-card-text>
        <div class="text-center" >
                  <v-progress-circular indeterminate> </v-progress-circular>
                </div>
        </v-card-text>
      </v-card>
      <v-card v-else flat>
                <v-card-title>{{ title }}</v-card-title>
                <v-card-text>
                <v-form v-model="value" ref="form">
                    <v-stepper flat v-model="curr">
    <v-stepper-header class="elevation-0">
      <v-stepper-step
        :complete="curr > 1"
        step="1"
        :color="stepStatus(1)"
      >
        step 1
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="curr > 2"
        step="2"
         :color="stepStatus(2)"
      >
        step 2
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step  :color="stepStatus(3)" step="3">
         step 3
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card flat>
        <v-card-text>
             <v-data-table
                  disable-pagination
                  hide-default-footer
                  dense
                  :headers="headers.coverages"
                  :items="first"
                >
                  <template v-slot:item.name="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item.description="{ item }">
                    <v-text-field
                      v-if="item.name.length <= 50"
                      outlined
                      hide-details="auto"
                      :placeholder="$t('description')"
                      dense
                      v-model="item.description"
                      :rules="rules.required"
                    ></v-text-field>
                    <v-textarea
                      v-else
                      outlined
                      hide-details="auto"
                      :placeholder="$t('description')"
                      dense
                      v-model="item.description"
                      :rules="rules.required"
                    >
                    </v-textarea>
                  </template>
                </v-data-table>
        </v-card-text>
        <v-card-actions>
         
        <v-spacer></v-spacer>

        <v-btn  color="primary" @click="curr = 2" >
          Continue
        </v-btn>
        </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-card
          class="mb-12"
          flat>
            <v-card-text>
                <v-data-table
                  disable-pagination
                  hide-default-footer
                  dense
                  :headers="headers.coverages"
                  :items="second">
                  <template v-slot:item.name="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item.description="{ item }">
                    <v-text-field
                      v-if="item.name.length <= 50"
                      outlined
                      hide-details="auto"
                      :placeholder="$t('description')"
                      dense
                      v-model="item.description"
                    ></v-text-field>
                    <v-textarea
                      v-else
                      outlined
                      hide-details="auto"
                      :placeholder="$t('description')"
                      dense
                      v-model="item.description"
                    >
                    </v-textarea>
                  </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="curr = 1">Back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="curr = 3">Continue</v-btn>
            </v-card-actions>
        </v-card>

       
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card flat
        >
        <v-card-text>
                 <v-data-table
                  disable-pagination
                  hide-default-footer
                  dense
                  :headers="headers.coverages"
                  :items="third"
                >
                  <template v-slot:item.name="{ item }">
                    {{ item.name }}
                  </template>
                  <template v-slot:item.description="{ item }">
                    <v-text-field
                      v-if="item.name.length <= 50"
                      outlined
                      hide-details="auto"
                      :placeholder="$t('description')"
                      dense
                      v-model="item.description"
                    ></v-text-field>
                    <v-textarea
                      v-else
                      outlined
                      hide-details="auto"
                      :placeholder="$t('description')"
                      dense
                      v-model="item.description"
                      :rules="rules.required"
                    >
                    </v-textarea>
                  </template>
                </v-data-table>
            
            </v-card-text>
            <v-card-actions>
             <v-btn
          color="primary"
          @click="curr = 2"
        >
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn  color="primary">
        Continue
        </v-btn>
            
            </v-card-actions>
             
        
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
                </v-form>
                </v-card-text>
                <v-card-actions>
                 <v-btn 
                        :disabled="!value" 
                        :loading="btnLoading"
                        text 
                        @click="submit"
                        >{{ $t("save") }}</v-btn>
                    <v-btn text @click="close">{{ $t("close") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
name: "DescriptionAllDailog",
 props: ["schemeId", "id"],
data() {
    return {
        display: true,
        curr:1,
        loadingPage: false,
        loading: false,
        btnLoading: false,
        value: false,
        coverages: [],
        third: [],
        second:[],
        first: [],
        form: {
coverages: [],
        },
        rules: {
            required: [(v) => !!v || this.$t("rules_required")],
        },
         headers: {
        coverages: [
          {
            text: this.$t("name"),
            width: "50%",
            sortable: false,
            value: "name",
          },
          {
            text: this.$t("description"),
            sortable: false,
            value: "description",
            width: "50%",
          },
        ],
         }
    }
},
computed: {
   title() {
       return this.id == null ? this.$t('Add Benfit') : this.$t('edit Benfit');
   }
},
methods: {
    ...mapActions("Api", ["post", "getAll", "updata"]),
    ...mapActions("snackBar", ["setSnackBars"]),

    submit() {
      var array = [];
      array = this.first
      array = array.concat(this.second);
      array = array.concat(this.third);
     
      this.form.coverages = array;
        this.btnLoading = true;
        if(this.id == null) {
          this.post({ url: `/schemes/${this.schemeId}/coverages`,  form: this.form })
         .then((res) => {
           this.btnLoading = false;
           this.setSnackBars({
             color: "success",
             message: this.$t("record_save_success"),
           });
             this.$emit('save');
             console.log(res);
         })
         .catch((error) => {
           this.btnLoading = false;
           let message = error.response
             ? error.response.data.message
             : error.message;
           this.setSnackBars({ color: "error", message: message });
         });

        } else {
            this.updata(`/schemes/${this.schemeId}/coverages/${this.id}?coverage_id=${this.form.coverage_id}&&description=${this.form.description}`)
         .then((res) => {
           this.btnLoading = false;
           this.setSnackBars({
             color: "success",
             message: this.$t("record_update_success"),
           });
             this.$emit('save');
             console.log(res);
         })
         .catch((error) => {
           this.btnLoading = false;
           let message = error.response
             ? error.response.data.message
             : error.message;
           this.setSnackBars({ color: "error", message: message });
         });
        }

    },
    getCoverages() {
        try {
            this.loading = true;
        this.getAll(`schemes/coverages`)
          .then( async (res) =>  {
            console.log(res.data.data);
            this.coverages = res.data.data;
            await this.coverages.forEach(element => {
              element.coverage_id= element.id;
              element.description = "";
              return element;
            });
            const threePartIndex = Math.ceil( this.coverages.length / 3);

 this.third =  this.coverages.splice(-threePartIndex);
 this.second =  this.coverages.splice(-threePartIndex);
this.first =  this.coverages;     

console.log(this.first);  // [1, 2, 3]
console.log( this.second); // [4, 5, 6]
console.log(this.third);


            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        } catch (error) {
            console.log('tag', error);
        }
        

    },
     stepStatus(step) {
            return this.curr > step ? 'green' : 'blue'
        },
     getCoverage() {
        try {
            this.loadingPage = true;
        this.getAll(`schemes/${this.schemeId}/coverages/${this.id}`)
          .then((res) => {
            console.log(res.data.data);
            this.form = res.data.data;
            this.form.coverage_id = this.form.coverageID;
            this.loadingPage = false;
          })
          .catch(() => {
            this.loadingPage = false;
          });
        } catch (error) {
            console.log('tag', error);
        }},
    close() {
      this.$emit("close");
    },
},
created() {
    this.getCoverages();
    if(this.id != null) this.getCoverage();
}
}
</script>

<style>

</style>