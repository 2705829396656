<template>
    <div>
        <v-dialog width="500" persistent v-model="display">
        <v-card flat v-if="loadingPage">
        <v-card-title></v-card-title>
        <v-card-text>
        <div class="text-center" >
                  <v-progress-circular indeterminate> </v-progress-circular>
                </div>
        </v-card-text>
      </v-card>
      <v-card v-else flat>
                <v-card-title>{{ title }}</v-card-title>
                <v-card-text>
                    <v-form v-model="value" ref="form">
                        <v-autocomplete 
                            dense
                            outlined
                            :loading="loading"
                            :label="$t('Benfit')"
                            :placeholder="$t('Benfit')"
                            item-value="id"
                            item-text="name"
                            :rules="rules.required"
                             v-model="form.coverage_id"
                            :items="coverages">
                        </v-autocomplete>
                         <v-text-field
                            dense
                             :label="$t('description')"
                            :placeholder="$t('description')"
                            :rules="rules.required"
                            outlined
                            v-model="form.description"
                            >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                 <v-btn 
                        :disabled="!value" 
                        :loading="btnLoading"
                        text 
                        @click="submit"
                        >{{ $t("save") }}</v-btn>
                    <v-btn text @click="close">{{ $t("close") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
name: "SchemeCoveragesDialog",
 props: ["schemeId", "id"],
data() {
    return {
        display: true,
        loadingPage: false,
        loading: false,
        btnLoading: false,
        value: false,
        coverages: [],
        form: {

        },
        rules: {
            required: [(v) => !!v || this.$t("rules_required")],
        },
    }
},
computed: {
   title() {
       return this.id == null ? this.$t('Add Benfit') : this.$t('edit Benfit');
   }
},
methods: {
    ...mapActions("Api", ["post", "getAll", "updata"]),
    ...mapActions("snackBar", ["setSnackBars"]),

    submit() {
        this.btnLoading = true;
        if(this.id == null) {
          this.post({ url: `/schemes/${this.schemeId}/coverages`,  form: this.form })
         .then((res) => {
           this.btnLoading = false;
           this.setSnackBars({
             color: "success",
             message: this.$t("record_save_success"),
           });
             this.$emit('save');
             console.log(res);
         })
         .catch((error) => {
           this.btnLoading = false;
           let message = error.response
             ? error.response.data.message
             : error.message;
           this.setSnackBars({ color: "error", message: message });
         });

        } else {
            this.updata(`/schemes/${this.schemeId}/coverages/${this.id}?coverage_id=${this.form.coverage_id}&&description=${this.form.description}`)
         .then((res) => {
           this.btnLoading = false;
           this.setSnackBars({
             color: "success",
             message: this.$t("record_update_success"),
           });
             this.$emit('save');
             console.log(res);
         })
         .catch((error) => {
           this.btnLoading = false;
           let message = error.response
             ? error.response.data.message
             : error.message;
           this.setSnackBars({ color: "error", message: message });
         });
        }

    },
    getCoverages() {
        try {
            this.loading = true;
        this.getAll(`schemes/coverages`)
          .then((res) => {
            console.log(res.data.data);
            this.coverages = res.data.data;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
        } catch (error) {
            console.log('tag', error);
        }
        

    },
     getCoverage() {
        try {
            this.loadingPage = true;
        this.getAll(`schemes/${this.schemeId}/coverages/${this.id}`)
          .then((res) => {
            console.log(res.data.data);
            this.form = res.data.data;
            this.form.coverage_id = this.form.coverageID;
            this.loadingPage = false;
          })
          .catch(() => {
            this.loadingPage = false;
          });
        } catch (error) {
            console.log('tag', error);
        }},
    close() {
      this.$emit("close");
    },
},
created() {
    this.getCoverages();
    if(this.id != null) this.getCoverage();
}
}
</script>

<style>

</style>