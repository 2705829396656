<template>
    <div>
        <v-dialog width="500" persistent v-model="display">
            <v-card flat>
                <v-card-title>{{ title }}</v-card-title>
                 <v-card flat v-if="loading">
        <v-card-title></v-card-title>
        <v-card-text>
        <div class="text-center" >
                  <v-progress-circular indeterminate> </v-progress-circular>
                </div>
        </v-card-text>
      </v-card>
                <v-card-text v-else>
                    <v-form v-model="value" ref="form">
                        <v-text-field 
                            outlined 
                            dense 
                            :rules="rules.required"
                            :label="$t('name')" 
                            :placeholder="$t('name')" 
                            v-model="form.name">
                        </v-text-field>
                        <v-textarea 
                            rows="3" 
                            outlined 
                            dense 
                            :rules="rules.required"
                            :label="$t('description')" 
                            :placeholder="$t('description')" 
                            v-model="form.description">
                        </v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn 
                        :disabled="!value" 
                        :loading="btnLoading"
                        text 
                      @click="submit"
                        >{{ $t("save") }}</v-btn>
                    <v-btn text @click="close">{{ $t("close") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: "DescriptionDailog",
    props:["id", "schemeId"],
    data() {
        return {
            display: true,
            value: false,
            btnLoading: false,
            loading :false,
            form: {

            },
            rules: {
                required: [(v) => !!v || this.$t("rules_required")],
            },
        }
    },
    computed: {
       title() {
           return this.id == null ? this.$t('add description') : this.$t('edit description');
       }
    },
    methods: {
        ...mapActions("Api", ["post", "getAll", "updata"]),
    ...mapActions("snackBar", ["setSnackBars"]),

    submit() {
           this.btnLoading = true;
        if(this.id == null ) {
        this.form.scheme_id	 = this.schemeId;
         this.post({ url: `/schemes/${this.schemeId}/description`,  form: this.form })
        .then((res) => {
          this.btnLoading = false;
          this.setSnackBars({
            color: "success",
            message: this.$t("record_save_success"),
          });
            this.$emit('save');
            console.log(res);
        })
        .catch((error) => {
          this.btnLoading = false;
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.setSnackBars({ color: "error", message: message });
        });

        } else {
             this.updata(`/schemes/${this.schemeId}/description/${this.id}?name=${this.form.name}&&description=${this.form.description}`)
        .then((res) => {
          this.btnLoading = false;
          this.setSnackBars({
            color: "success",
            message: this.$t("record_update_success"),
          });
            this.$emit('save');
            console.log(res);
        })
        .catch((error) => {
          this.btnLoading = false;
          let message = error.response
            ? error.response.data.message
            : error.message;
          this.setSnackBars({ color: "error", message: message });
        });
        }
     
    },

    getDescription() {
        this.loading = true;
        this.getAll(`/schemes/${this.schemeId}/description/${this.id}`)
            .then((res) => {
           this.form = res.data.data;
                console.log(res);
                 this.loading = false;
            })
            .catch((error) => {
            this.loading = false;
           console.error('tag', error);
            });
    },
        close() {
            this.$emit('close');
        }
    },
    created() {
        if(this.id != null) this.getDescription();
    }
}
</script>

<style>

</style>